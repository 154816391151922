import { Outlet, Navigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { config } from '../constants'
import { Crisp } from 'crisp-sdk-web'

type ContextType = {
  firstName: string
  lastName: string
  subscription_status: string | null
}

const PrivateRoutes = () => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['userData'],
    queryFn: () =>
      fetch(`${config.API_URL}api/users/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('demopondAuthToken')}`,
        },
      }).then((res) => res.json()),
  })
  const authToken = localStorage.getItem('demopondAuthToken')
  if (!authToken) {
    return <Navigate to='/login' />
  }

  if (!data) return null

  if (isLoading) return <div>Loading...</div>

  if (isError && error instanceof Error)
    return <div>An error occurred: {error.message}</div>

  if (data.code === 'token_not_valid') {
    localStorage.removeItem('demopondAuthToken')
    return <Navigate to='/login' />
  }

  Crisp.user.setEmail(data.email)
  Crisp.user.setNickname(data.first_name + ' ' + data.last_name)

  return (
    <Outlet
      context={
        {
          firstName: data.first_name,
          lastName: data.last_name,
          subscription_status: data.subscription_status,
        } satisfies ContextType
      }
    />
  )
}

export default PrivateRoutes
